[data-bs-theme=dark] {
  --bs-body-bg: #1e2022 !important;
}

.navbar {
  padding-top: 0px !important;
}

.navbar-brand img {
  height: 35px;
}

.app-email {
  line-height: 7px;
  margin-top: 16px;
}

.table > tbody > tr{
    --bs-table-bg: rgba(var(--bs-emphasis-color-rgb),0.05);
}

.moana-brain-logo {
  width: 60px;
  height: 70px;
}

.moana-brain-logo.logo-white path {
  fill: white;
}