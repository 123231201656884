:root {
    /* the default color for light mode */
    --card-bg-color: #fff;
    --card-text-color: #1e2022;
    --card-border-color: rgba(231, 234, 243, 0.7);
    --card-box-shadow: 0 .375rem .75rem rgba(140, 152, 164, .075);
    --input-border: .0625rem solid rgba(231, 234, 243, .7);
    --field-description-color: rgba(103, 119, 136, 1);
    --th-background-color: #f8fafd;
    --tr-hover-background-color: #f8fafd;
}

.dark-mode {
    /* the default color for dark mode */
    --card-bg-color: #25282a;
    --card-text-color: #fff;
    --card-border-color: #2f3235;
    --card-box-shadow: 0 .375rem .75rem rgba(30, 32, 34, .6);
    --bs-body-bg: #1e2022;
    --input-border: .0625rem solid #2f3235;
    --field-description-color: rgba(197, 200, 204, 1);
    --th-background-color: #2f3235;
    --th-hover-background-color: #2f3235;
}

.card {
    background-color: var(--card-bg-color);
    border-color: var(--card-border-color);
    box-shadow: var(--card-box-shadow);
    color: var(--card-text-color);
}

.card-header {
    background-color: var(--card-bg-color);
    border-color: var(--card-border-color);
    border-bottom: 0.0625rem solid var(--card-border-color);
    font-size: 1.125rem;
    font-weight: 600;
    padding: 1rem;
}

.card-body {
    padding-left: 1.3125rem;
    padding-right: 1.3125rem;
    font-size: 1rem;
}

.card-footer {
    border-top: none;
}

.loading-cube-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
}

.data-detail-datetime {
    font-size: .75rem;
}

.logo {
    position: absolute;
    z-index: 999;
    width: 166px;
    left: 74px;
    bottom: -25px;
}

.logo path {
    fill: white;
}