.DisplayImg {
  position: relative;
  width: 100%;
  margin: auto;
}

.DisplayImg > h2 {
  position: absolute;
  top: 88%;
  left: 6%;
  width: 100%;
  text-align: left;
  padding: 0 5px;
  font-size: 0.8em;
  color: black;
  text-shadow: 0px 0px black;
  line-height: 130%
}

.DisplayImg > h3 {
  position: absolute;
  top: 83%;
  left: 40%;
  width: 100%;
  text-align: left;
  padding: 0 5px;
  font-size: 1em;
  color: black;
  text-shadow: 0px 0px black;
}

@media (max-width: 800px) {
  .DisplayImg > h2 {
    font-size: 1em;
  }
  .DisplayImg > .labelDetails {
    top: 90% !important;
  }
}

h2 > p {
  margin-bottom: 0rem; /* between paragraphs */
}