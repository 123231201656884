.result-list-item {
    padding: 1.3125rem 0;
    background-color: transparent;
    display: flex;
    align-items: center;
}

.result-list-icon {
    padding: 0 1.3125rem 0 0.375rem;
}

.result-icon {
    width: 20px;
    fill: #c5c8cc;
}

.result-list-name {
    flex: 2;
}

.result-list-number {
    flex: 1;
    text-align: right;
}


.loader {
    margin-left: auto;
    width: 40px;
    aspect-ratio: 4;
    --_g: no-repeat radial-gradient(circle closest-side, var(--card-text-color) 90%, #0000);
    background:
        var(--_g) 0% 50%,
        var(--_g) 50% 50%,
        var(--_g) 100% 50%;
    background-size: calc(100%/3) 100%;
    animation: loader 1s infinite linear;
}

@keyframes loader {
    33% {
        background-size: calc(100%/3) 0%, calc(100%/3) 100%, calc(100%/3) 100%
    }

    50% {
        background-size: calc(100%/3) 100%, calc(100%/3) 0%, calc(100%/3) 100%
    }

    66% {
        background-size: calc(100%/3) 100%, calc(100%/3) 100%, calc(100%/3) 0%
    }
}