.field-item:not(:last-child) {
    margin-bottom: .375rem;
}

.field-label {
    /* font-size: .875rem; */
    text-align: right;
}

.field-input {
    border: var(--input-border);
}

.field-description {
    font-size: .75rem;
    color: var(--field-description-color);
}