.table {
    background-color: transparent;
}

.table-head th {
    font-weight: 400;
    background-color: var(--th-background-color);
    border-bottom: none;
    padding: .875rem 1.3125rem;
}

.table-body tr:hover {
    background-color: var(--th-hover-background-color);
}

.table-body td {
    background: transparent;
    border-bottom: none;
    padding: 1.2rem;
    font-size: .875rem;
    vertical-align: middle;
}

.pagination {
    margin: 0;
    --bs-pagination-color: var(--card-text-color);
    --bs-pagination-hover-color: var(--card-text-color);
}
