:root {
    --cube-width: 86px;
    --cube-height: 100px;
    --face-size: 50px;
    --anim-duration: 6s;
}

.container {
    position: relative;
    height: 100px;
    width: 86px;
    transform: scale(0.6);
}

.cube {
    position: absolute;
    width: var(--cube-width);
    height: var(--cube-height);
    transform: translate(var(--tx0, 0), var(--ty0, 0));
}

.animate .cube {
    animation: move var(--anim-duration) ease infinite;
}

.right {
    background: #E79C10;
    transform: rotate(-30deg) skewX(-30deg) translate(49px, 65px) scaleY(0.86);
}

.left {
    background: #D53A33;
    transform: rotate(90deg) skewX(-30deg) scaleY(0.86) translate(25px, -50px);
}

.top {
    background: #1d9099;
    transform: rotate(210deg) skew(-30deg) translate(-75px, -22px) scaleY(0.86);
    z-index: 2;
}

.face {
    height: 50px;
    width: 50px;
    position: absolute;
    transform-origin: 0 0;
}

.h1 {
    z-index: -1;
}

.h2 {
    z-index: -2;
}

.h3 {
    z-index: -3;
}

@keyframes move {
    0% {
        transform: translate(var(--tx0), var(--ty0));
    }

    14% {
        transform: translate(var(--tx14), var(--ty14));
    }

    28% {
        transform: translate(var(--tx28), var(--ty28));
    }

    43% {
        transform: translate(var(--tx43), var(--ty43));
    }

    57% {
        transform: translate(var(--tx57), var(--ty57));
    }

    71% {
        transform: translate(var(--tx71), var(--ty71));
    }

    85% {
        transform: translate(var(--tx85), var(--ty85));
    }

    100% {
        transform: translate(var(--tx0), var(--ty0));
    }
}

.h1.w1.l1 {
    --tx0: 0%;
    --ty0: -100%;
    --tx14: -50%;
    --ty14: -125%;
    --tx28: 0%;
    --ty28: -150%;
    --tx43: 0%;
    --ty43: -200%;
    --tx57: 50%;
    --ty57: -175%;
    --tx71: 0%;
    --ty71: -150%;
    --tx85: 0%;
    --ty85: -100%;
}

.h1.w1.l2 {
    --tx0: 50%;
    --ty0: -75%;
    --tx14: 50%;
    --ty14: -75%;
    --tx28: 100%;
    --ty28: -100%;
    --tx43: 100%;
    --ty43: -150%;
    --tx57: 100%;
    --ty57: -150%;
    --tx71: 50%;
    --ty71: -125%;
    --tx85: 50%;
    --ty85: -75%;
}

.h1.w1.l3 {
    --tx0: 100%;
    --ty0: -50%;
    --tx14: 150%;
    --ty14: -25%;
    --tx28: 200%;
    --ty28: -50%;
    --tx43: 200%;
    --ty43: -100%;
    --tx57: 150%;
    --ty57: -125%;
    --tx71: 100%;
    --ty71: -100%;
    --tx85: 100%;
    --ty85: -50%;
}

.h1.w2.l1 {
    --tx0: -50%;
    --ty0: -75%;
    --tx14: -100%;
    --ty14: -100%;
    --tx28: -100%;
    --ty28: -100%;
    --tx43: -100%;
    --ty43: -150%;
    --tx57: -50%;
    --ty57: -125%;
    --tx71: -50%;
    --ty71: -125%;
    --tx85: -50%;
    --ty85: -75%;
}

.h1.w2.l2 {
    --tx0: 0%;
    --ty0: -50%;
    --tx14: 0%;
    --ty14: -50%;
    --tx28: 0%;
    --ty28: -50%;
    --tx43: 0%;
    --ty43: -100%;
    --tx57: 0%;
    --ty57: -100%;
    --tx71: 0%;
    --ty71: -100%;
    --tx85: 0%;
    --ty85: -50%;
}

.h1.w2.l3 {
    --tx0: 50%;
    --ty0: -25%;
    --tx14: 100%;
    --ty14: 0%;
    --tx28: 100%;
    --ty28: 0%;
    --tx43: 100%;
    --ty43: -50%;
    --tx57: 50%;
    --ty57: -75%;
    --tx71: 50%;
    --ty71: -75%;
    --tx85: 50%;
    --ty85: -25%;
}

.h1.w3.l1 {
    --tx0: -100%;
    --ty0: -50%;
    --tx14: -150%;
    --ty14: -75%;
    --tx28: -200%;
    --ty28: -50%;
    --tx43: -200%;
    --ty43: -100%;
    --tx57: -150%;
    --ty57: -75%;
    --tx71: -100%;
    --ty71: -100%;
    --tx85: -100%;
    --ty85: -50%;
}

.h1.w3.l2 {
    --tx0: -50%;
    --ty0: -25%;
    --tx14: -50%;
    --ty14: -25%;
    --tx28: -100%;
    --ty28: 0%;
    --tx43: -100%;
    --ty43: -50%;
    --tx57: -100%;
    --ty57: -50%;
    --tx71: -50%;
    --ty71: -75%;
    --tx85: -50%;
    --ty85: -25%;
}

.h1.w3.l3 {
    --tx0: 0%;
    --ty0: 0%;
    --tx14: 50%;
    --ty14: 25%;
    --tx28: 0%;
    --ty28: 50%;
    --tx43: 0%;
    --ty43: 0%;
    --tx57: -50%;
    --ty57: -25%;
    --tx71: 0%;
    --ty71: -50%;
    --tx85: 0%;
    --ty85: 0%;
}

.h2.w1.l1 {
    --tx0: 0%;
    --ty0: -50%;
    --tx14: -50%;
    --ty14: -75%;
    --tx28: 0%;
    --ty28: -100%;
    --tx43: 0%;
    --ty43: -100%;
    --tx57: 50%;
    --ty57: -75%;
    --tx71: 0%;
    --ty71: -50%;
    --tx85: 0%;
    --ty85: -50%;
}

.h2.w1.l2 {
    --tx0: 50%;
    --ty0: -25%;
    --tx14: 50%;
    --ty14: -25%;
    --tx28: 100%;
    --ty28: -50%;
    --tx43: 100%;
    --ty43: -50%;
    --tx57: 100%;
    --ty57: -50%;
    --tx71: 50%;
    --ty71: -25%;
    --tx85: 50%;
    --ty85: -25%;
}

.h2.w1.l3 {
    --tx0: 100%;
    --ty0: 0%;
    --tx14: 150%;
    --ty14: 25%;
    --tx28: 200%;
    --ty28: 0%;
    --tx43: 200%;
    --ty43: 0%;
    --tx57: 150%;
    --ty57: -25%;
    --tx71: 100%;
    --ty71: 0%;
    --tx85: 100%;
    --ty85: 0%;
}

.h2.w2.l1 {
    --tx0: -50%;
    --ty0: -25%;
    --tx14: -100%;
    --ty14: -50%;
    --tx28: -100%;
    --ty28: -50%;
    --tx43: -100%;
    --ty43: -50%;
    --tx57: -50%;
    --ty57: -25%;
    --tx71: -50%;
    --ty71: -25%;
    --tx85: -50%;
    --ty85: -25%;
}

.h2.w2.l2 {
    --tx0: 0%;
    --ty0: 0%;
    --tx14: 0%;
    --ty14: 0%;
    --tx28: 0%;
    --ty28: 0%;
    --tx43: 0%;
    --ty43: 0%;
    --tx57: 0%;
    --ty57: 0%;
    --tx71: 0%;
    --ty71: 0%;
    --tx85: 0%;
    --ty85: 0%;
}

.h2.w2.l3 {
    --tx0: 50%;
    --ty0: 25%;
    --tx14: 100%;
    --ty14: 50%;
    --tx28: 100%;
    --ty28: 50%;
    --tx43: 100%;
    --ty43: 50%;
    --tx57: 50%;
    --ty57: 25%;
    --tx71: 50%;
    --ty71: 25%;
    --tx85: 50%;
    --ty85: 25%;
}

.h2.w3.l1 {
    --tx0: -100%;
    --ty0: 0%;
    --tx14: -150%;
    --ty14: -25%;
    --tx28: -200%;
    --ty28: 0%;
    --tx43: -200%;
    --ty43: 0%;
    --tx57: -150%;
    --ty57: 25%;
    --tx71: -100%;
    --ty71: 0%;
    --tx85: -100%;
    --ty85: 0%;
}

.h2.w3.l2 {
    --tx0: -50%;
    --ty0: 25%;
    --tx14: -50%;
    --ty14: 25%;
    --tx28: -100%;
    --ty28: 50%;
    --tx43: -100%;
    --ty43: 50%;
    --tx57: -100%;
    --ty57: 50%;
    --tx71: -50%;
    --ty71: 25%;
    --tx85: -50%;
    --ty85: 25%;
}

.h2.w3.l3 {
    --tx0: 0%;
    --ty0: 50%;
    --tx14: 50%;
    --ty14: 75%;
    --tx28: 0%;
    --ty28: 100%;
    --tx43: 0%;
    --ty43: 100%;
    --tx57: -50%;
    --ty57: 75%;
    --tx71: 0%;
    --ty71: 50%;
    --tx85: 0%;
    --ty85: 50%;
}

.h3.w1.l1 {
    --tx0: 0%;
    --ty0: 0%;
    --tx14: -50%;
    --ty14: -25%;
    --tx28: 0%;
    --ty28: -50%;
    --tx43: 0%;
    --ty43: 0%;
    --tx57: 50%;
    --ty57: 25%;
    --tx71: 0%;
    --ty71: 50%;
    --tx85: 0%;
    --ty85: 0%;
}

.h3.w1.l2 {
    --tx0: 50%;
    --ty0: 25%;
    --tx14: 50%;
    --ty14: 25%;
    --tx28: 100%;
    --ty28: 0%;
    --tx43: 100%;
    --ty43: 50%;
    --tx57: 100%;
    --ty57: 50%;
    --tx71: 50%;
    --ty71: 75%;
    --tx85: 50%;
    --ty85: 25%;
}

.h3.w1.l3 {
    --tx0: 100%;
    --ty0: 50%;
    --tx14: 150%;
    --ty14: 75%;
    --tx28: 200%;
    --ty28: 50%;
    --tx43: 200%;
    --ty43: 100%;
    --tx57: 150%;
    --ty57: 75%;
    --tx71: 100%;
    --ty71: 100%;
    --tx85: 100%;
    --ty85: 50%;
}

.h3.w2.l1 {
    --tx0: -50%;
    --ty0: 25%;
    --tx14: -100%;
    --ty14: 0%;
    --tx28: -100%;
    --ty28: 0%;
    --tx43: -100%;
    --ty43: 50%;
    --tx57: -50%;
    --ty57: 75%;
    --tx71: -50%;
    --ty71: 75%;
    --tx85: -50%;
    --ty85: 25%;
}

.h3.w2.l2 {
    --tx0: 0%;
    --ty0: 50%;
    --tx14: 0%;
    --ty14: 50%;
    --tx28: 0%;
    --ty28: 50%;
    --tx43: 0%;
    --ty43: 100%;
    --tx57: 0%;
    --ty57: 100%;
    --tx71: 0%;
    --ty71: 100%;
    --tx85: 0%;
    --ty85: 50%;
}

.h3.w2.l3 {
    --tx0: 50%;
    --ty0: 75%;
    --tx14: 100%;
    --ty14: 100%;
    --tx28: 100%;
    --ty28: 100%;
    --tx43: 100%;
    --ty43: 150%;
    --tx57: 50%;
    --ty57: 125%;
    --tx71: 50%;
    --ty71: 125%;
    --tx85: 50%;
    --ty85: 75%;
}

.h3.w3.l1 {
    --tx0: -100%;
    --ty0: 50%;
    --tx14: -150%;
    --ty14: 25%;
    --tx28: -200%;
    --ty28: 50%;
    --tx43: -200%;
    --ty43: 100%;
    --tx57: -150%;
    --ty57: 125%;
    --tx71: -100%;
    --ty71: 100%;
    --tx85: -100%;
    --ty85: 50%;
}

.h3.w3.l2 {
    --tx0: -50%;
    --ty0: 75%;
    --tx14: -50%;
    --ty14: 75%;
    --tx28: -100%;
    --ty28: 100%;
    --tx43: -100%;
    --ty43: 150%;
    --tx57: -100%;
    --ty57: 150%;
    --tx71: -50%;
    --ty71: 125%;
    --tx85: -50%;
    --ty85: 75%;
}

.h3.w3.l3 {
    --tx0: 0%;
    --ty0: 100%;
    --tx14: 50%;
    --ty14: 125%;
    --tx28: 0%;
    --ty28: 150%;
    --tx43: 0%;
    --ty43: 200%;
    --tx57: -50%;
    --ty57: 175%;
    --tx71: 0%;
    --ty71: 150%;
    --tx85: 0%;
    --ty85: 100%;
}